import { AutocompleteCollection } from '@algolia/autocomplete-core'
import { useLazyQuery, useQuery } from '@graphcommerce/graphql'
import { AddProductsToCartForm } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { Trans } from '@lingui/react'
import { Box, BoxProps, Typography, Link, debounce, useEventCallback } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { UseCustomerProductPricesDocument } from '../../../Product/UseCustomerProductPrices.gql'
import { useCustomerProductPrices } from '../../../Product/useCustomerProductPrices'
import { AutocompleteProductItem, ProductHit } from '../Hits/ProductHit'

type collectionProps = {
  collection: AutocompleteCollection<AutocompleteProductItem>
  listProps: BoxProps<'ul'>
  getItemProps: any
  query: string
  nbProductHits: string | unknown
  handleItemClicked?: () => void
}

export function ProductsCollection(props: collectionProps) {
  const { collection, listProps, getItemProps, query, nbProductHits, handleItemClicked } = props
  const { source, items } = collection
  const { locale } = useRouter()
  const urlLocale = locale === 'en' ? '' : `/${locale}`

  const [exec, result] = useLazyQuery(UseCustomerProductPricesDocument, {})
  const productsWithCustomerSpecificPrices = useCustomerProductPrices(result.data?.products)

  const { data: config } = useQuery(StoreConfigDocument)
  const storeCode = config?.storeConfig?.store_code
  const algoliaIndexProducts = `magento2_${storeCode}_products`

  const debouncedExec = useEventCallback(debounce(exec, 200))
  useEffect(() => {
    const skus = items.map((i) => (typeof i.sku === 'string' ? i.sku : i.sku[0]))

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    debouncedExec({ variables: { pageSize: 8, skus } })
  }, [debouncedExec, locale, items])

  return (
    <AddProductsToCartForm>
      <Box className='aa-Source' sx={{ width: '100%', height: '100%' }}>
        {items.length > 0 ? (
          <>
            <Box
              component='ul'
              className='aa-List'
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: {
                  xs: '1fr',
                  md: 'repeat(4, 1fr)',
                },
                gridGap: { xs: theme.spacings.sm, md: theme.spacings.xs },
                margin: '0',
                padding: { xs: '0', md: theme.spacings.xxs },
              })}
              {...listProps}
            >
              {items.map((item) => (
                <ProductHit
                  key={item.objectID}
                  hit={item}
                  handleItemClicked={handleItemClicked}
                  price={
                    productsWithCustomerSpecificPrices?.items?.find((i) => i?.sku === item.sku)
                      ?.price_range.minimum_price
                  }
                  {...getItemProps({
                    item,
                    source,
                  })}
                />
              ))}
            </Box>
            <Box
              component='footer'
              sx={(theme) => ({
                backgroundColor: 'divider',
                borderBottomRightRadius: { xs: 0, sm: 1 },
                mt: { xs: theme.spacings.sm, md: 0 },
                padding: theme.spacings.xxs,
                display: 'flex',
                justifyContent: 'center',
              })}
            >
              <Typography>
                <Trans id='See products in' />{' '}
                <Link
                  href={`${urlLocale}/search/?${algoliaIndexProducts}%5Bquery%5D=${query}`}
                  onClick={handleItemClicked}
                  sx={{ fontWeight: 600 }}
                >
                  <Trans id='all departments' />{' '}
                </Link>
                <>({nbProductHits})</>
              </Typography>
            </Box>
          </>
        ) : (
          <Box
            sx={(theme) => ({
              backgroundColor: 'divider',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              padding: theme.spacings.sm,
            })}
          >
            <Typography component='em'>
              <Trans id='No products found for search query' /> &quot;{query}&quot;
            </Typography>
          </Box>
        )}
      </Box>
    </AddProductsToCartForm>
  )
}

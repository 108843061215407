import { Hit } from '@algolia/client-search'
import * as Types from '@graphcommerce/graphql-mesh/.mesh'
import { Image } from '@graphcommerce/image'
import { useCustomerQuery, useCustomerSession } from '@graphcommerce/magento-customer'
import { AddProductsToCartButton } from '@graphcommerce/magento-product'
import { ProductListPrice } from '@graphcommerce/magento-product/components/ProductListPrice/ProductListPrice'
import {
  iconCheckmark,
  iconClose,
  IconSvg,
  iconShoppingBag,
  responsiveVal,
} from '@graphcommerce/next-ui'
import { Box, Typography, ButtonBase } from '@mui/material'
import { useRouter } from 'next/router'
import { AudienceDocument } from '../../../../graphql/Audience.gql'
import { useStoreData } from '../../../../lib/hooks/useStoreData'
import { ProductSignInButton } from '../../../Product/ProductSignInButton'

export type AutocompleteProductItem = Hit<{
  brand: string
  categories: string[]
  image: string
  name: string
  objectID: string
  url: string
  url_key: string
  image_url: string
  image_label_html: string
  merk: string
  sku: string
  delivery: {
    b2c: string
    b2b: string
  }
  type_id: string
  is_salable: {
    b2c: boolean
    b2b: boolean
  }
  price: {
    EUR: {
      default
    }
  }
}>

type PriceFragment = {
  tax: { currency?: Types.CurrencyEnum | null; value?: number | null }
  regular_price: { currency?: Types.CurrencyEnum | null; value?: number | null }
  discount?: { amount_off?: number | null; percent_off?: number | null } | null
  final_price: { currency?: Types.CurrencyEnum | null; value?: number | null }
}

export function ProductHit(props: {
  hit: AutocompleteProductItem
  price?: PriceFragment
  handleItemClicked: () => void
}) {
  const { hit, price, handleItemClicked } = props
  const { delivery, is_salable } = hit

  const { loggedIn } = useCustomerSession()
  const { mode } = useStoreData()
  const storeB2B = mode === 'b2b'
  const loggedOutB2B = !loggedIn && storeB2B

  const { locale } = useRouter()
  const urlLocale = locale === 'en' ? '' : `/${locale}`

  const { data: audience } = useCustomerQuery(AudienceDocument)
  const customerType = audience?.customer?.audience || 'b2c'
  const inStock = Boolean(is_salable[customerType])

  const getProductUrl = (productItem: AutocompleteProductItem) => {
    const urlKey = productItem.url_key
    const url = `/p/${urlKey}`

    return url
  }

  return (
    <Box component='li' className='aa-Item' sx={{ display: 'flex' }}>
      <ButtonBase
        href={`${urlLocale}${getProductUrl(hit)}`}
        sx={(theme) => ({
          cursor: 'pointer',
          flex: '0 1 100%',
          display: 'grid',
          gap: theme.spacings.xs,
          gridTemplateAreas: {
            xs: `
            "image info info"
            "delivery delivery price"
          `,
            md: `
            "image"
            "info"
            "price"
            "delivery"
          `,
          },
          gridTemplateColumns: {
            xs: '75px 2fr 2fr',
            md: '1fr',
          },
          gridTemplateRows: {
            md: '120px 3fr 1fr 1fr',
          },

          '&:hover .ais-hit-title': { textDecoration: 'underline' },
        })}
        onClick={handleItemClicked}
      >
        <Box sx={{ gridArea: 'image', position: 'relative' }}>
          {hit?.image_label_html && (
            <Box
              component='span'
              sx={{
                position: 'absolute',
                zIndex: 99,
                top: 0,
                left: 0,

                '& .amasty-label-container': {
                  display: 'block !important',
                },
                '& img': {
                  maxWidth: '85px',
                },
                '& .amlabel-text:empty': {
                  display: 'none',
                },
                '& .amlabel-text': {
                  borderRadius: 1,
                  backgroundColor: 'text.primary',
                  color: 'primary.contrastText',
                  fontSize: responsiveVal(13, 15),
                  padding: '5px 10px',
                },
              }}
              dangerouslySetInnerHTML={{
                // force html to one line and remove whitespace so our :empty selector works
                __html: hit?.image_label_html
                  .replace(/[\n\r\t\s]+/g, ' ')
                  .replace('<div class="amlabel-text"> </div>', '<div class="amlabel-text"></div>'),
              }}
            />
          )}
          <Image
            className='ais-hit-image'
            src={hit?.image_url ?? ''}
            alt={hit?.name}
            loading='lazy'
            width={120}
            height={120}
            sizes='120px'
            pictureProps={{
              sx: {
                display: 'flex',
                justifyContent: { md: 'center' },
              },
            }}
            sx={{
              width: '100%',
              maxWidth: { xs: '75px', md: '120px' },
              maxHeight: { xs: '75px', md: '120px' },
            }}
          />
        </Box>
        <Box
          sx={(theme) => ({
            gridArea: 'info',
            alignSelf: 'start',
            display: 'grid',
            gridTemplateColumns: 'unset',
            marginTop: { md: theme.spacings.xs },
            rowGap: { xs: 0.2, md: 1 },
            columnGap: 2,
            gridTemplateAreas: `
            "title"
            "brand"
            "sku"
          `,
            [theme.breakpoints.up('md')]: {
              gridTemplateAreas: `
              "title title"
              "brand sku"
            `,
              gridTemplateColumns: 'auto 1fr',
            },
          })}
        >
          <Typography
            className='ais-hit-title'
            variant='h5'
            sx={{
              gridArea: 'title',
              color: 'text.primary',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              maxWidth: '100%',
              fontWeight: 'fontWeightBold',
              lineHeight: 1.3,
              flex: '0 1 100%',
            }}
          >
            {hit.name}
          </Typography>
          <Typography
            className='ais-hit-brand'
            sx={{
              gridArea: 'brand',
              color: 'text.secondary',
              typography: 'body1',
            }}
          >
            {hit.merk}
          </Typography>
          <Box
            sx={{
              gridArea: 'sku',
              color: 'text.disabled',
              typography: 'body1',
            }}
          >
            {Array.isArray(hit.sku) ? hit.sku.at(0) : hit.sku}
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            gridArea: 'price',
            display: 'flex',
            flexWrap: { xs: 'wrap', md: 'nowrap' },
            justifyContent: { xs: 'end', md: 'space-between' },
            gap: theme.spacings.xxs,
            alignItems: { xs: 'start', md: 'center' },
            marginLeft: { xs: 'auto', md: '0' },
          })}
        >
          {loggedOutB2B ? (
            <ProductSignInButton />
          ) : (
            <>
              {price && (
                <ProductListPrice
                  {...price}
                  sx={{
                    textAlign: 'right',
                    justifySelf: { sm: 'flex-end' },
                    color: 'primary.main',
                    typography: 'h5',
                  }}
                />
              )}
            </>
          )}
          {hit.type_id === 'simple' && (
            <Box sx={{ marginLeft: 'auto' }}>
              {hit.sku && inStock && !loggedOutB2B && (
                <AddProductsToCartButton
                  sku={hit.sku}
                  size='medium'
                  variant='contained'
                  color='secondary'
                  sx={(theme) => ({
                    px: theme.spacings.xxs,
                  })}
                >
                  <IconSvg size='large' src={iconShoppingBag} />
                </AddProductsToCartButton>
              )}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            gridArea: 'delivery',
            display: 'grid',
            gridTemplateColumns: `min-content auto`,
            alignItems: 'center',
            gap: responsiveVal(5, 10),
            color: inStock ? 'secondary.main' : 'text.main',

            '& > span': {
              lineHeight: 1.2,
            },
          }}
        >
          <IconSvg
            src={inStock ? iconCheckmark : iconClose}
            sx={[
              { flexShrink: 0 },
              inStock && {
                transform: 'translateY(-4px)',
                alignSelf: 'start',
              },
              !inStock && {
                alignSelf: 'start',
              },
            ]}
          />
          <Typography
            variant='caption'
            dangerouslySetInnerHTML={{ __html: delivery[customerType] }}
          />
        </Box>
      </ButtonBase>
    </Box>
  )
}
